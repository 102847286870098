import "./style.css";

const Footer = () => (
  <footer>
    <div className="footer-links">
      <a href="https://www.linkedin.com/in/joao-paulo-vanzuita">LinkedIn</a>{" "}
      <span className="gray-it">/ </span>
      <a href="http://github.com/converge">GitHub</a>
    </div>
  </footer>
);

export default Footer;
