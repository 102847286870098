import AboutMe from "../AboutMe";
import BlogPosts from "../Blog/posts";
import { Helmet } from "react-helmet";
import { SEO } from "../Seo";

const MainContent = (props) => {

		return(
				<main>
						<SEO
								title={"João Vanzuita, a backend developer."}
								description={
										"I'm João Vanzuita, a backend developer. I love Go Lang, PostgreSQL and oAuth framework. I'm also passionate about cycling."
								}
						/>
						<AboutMe />
						<hr />
						<BlogPosts />
				</main>
		)
};

export default MainContent;
