import profilePicture from "url:../../../assets/joaovanzuita.jpg";
import "./style.css";

const AboutMe = () => {
  return (
    <section>
      <div className="about-me">
        <div className="profile-pic">
          <img src={profilePicture} alt="profile picture" />
        </div>
        <div className="about-me-content">
          <h2>About me</h2>

          <div className="about-me-content-line">
            Welcome to my personal website! I'm a senior Go developer with a passion for technology and a dedication to creating efficient and effective software. With years of experience in the field, I pride myself on staying up-to-date with the latest trends and advancements in the tech industry.
          </div>

          <div className="about-me-content-line">
            In addition to my professional pursuits, I'm also a proud vegetarian and avid cyclist. I believe that taking care of ourselves and the environment is of utmost importance, and these values guide my personal and professional decisions.
          </div>

          <div className="about-me-content-line">
            Whether you're interested in learning more about my work in the tech industry, my experiences as a vegetarian, or my adventures on two wheels, I'm excited to share my passions with you through this platform. Thanks for stopping by!
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
