import "./style.css";

const Header = () => (
		<header>
				<a href="../">
						<span className="big_hi">Hi,</span> I'm João Vanzuita, a Backend software
						developer.
				</a>
		</header>
)

export default Header;
