import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { SEO } from "../Seo";
import "./style.css";

const BlogPost = () => {
  const [blogPost, setBlogPost] = useState(null);

  const { slug } = useParams();
  const findBlogPostBySlung = (slug) => {
    return `
    {
      pageCollection (limit: 1, where: {slug:"${slug}"}) {
        total
        items {
          title
          description
          slug
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                  description
                  contentType
                }
              }
            }
          }
          created
        }
      }
    } `;
  };
  const query = findBlogPostBySlung(slug);

  // those values are read only
  // workspace Id only identify where to read data from
  const workspaceId = process.env.WORKSPACE_ID;
  // CPA_TOKEN is a ready only token to enable http requests to Contentful
  const cpaToken = process.env.CPA_TOKEN;

  useEffect(() => {
    window
      .fetch(
        `https://graphql.contentful.com/content/v1/spaces/${workspaceId}/`,
        {
          method: "POST",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cpaToken}`,
          },
          body: JSON.stringify({ query }),
        }
      )
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        // console.log(data.pageCollection.items[0]);
        setBlogPost(data.pageCollection.items[0]);
      });
  }, [workspaceId, cpaToken]);

  if (blogPost == null) {
    return (
      <div className="blog-single-post">
        <h1>loading...</h1>
      </div>
    );
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (
          node.content.length === 1 &&
          node.content[0].marks.find((x) => x.type === "code")
        ) {
          return <div>{children}</div>;
        }
        return <p>{children}</p>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const img = blogPost.content.links.assets.block.find(
          (i) => i.sys.id === node.data.target.sys.id
        );
        return <img src={img?.url} width={img?.width} alt={img?.title} />;
      },
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.includes("https://www.youtube.com/watch")) {
          const videoId = node.data.uri.split("watch?v=").pop();
          return (
            <div className="embed-video">
              <iframe
                width="350"
                height="250"
                // src="https://www.youtube.com/embed/Nvamav9N86w"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          );
        }
        console.log(node);
        return <a href={node.data.uri}>{node.content[0].value}</a>;
      },
    },
    renderMark: {
      [MARKS.CODE]: (text) => {
        return (
          <SyntaxHighlighter language="go" style={nord} showLineNumbers>
            {text}
          </SyntaxHighlighter>
        );
      },
    },
  };

  return (
    <div className="blog-single-post">
      <SEO title={blogPost.title} description={blogPost.description} />
      <div className="blog-title">
        <h1>{blogPost.title}</h1>
      </div>
      <div className="blog-content">
        {documentToReactComponents(blogPost.content.json, options)}
      </div>
    </div>
  );
};

export default BlogPost;
