import { render } from "react-dom";
import App from "./container/App";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-HYNEQ2F9CT");

const app = document.getElementById("root");

render(
		<BrowserRouter>
				<App />
		</BrowserRouter>,
		app
);
