import { useEffect, useState } from "react";

const BlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState(null);

  const query = `
  {
    pageCollection {
      total
      items {
        title
        slug
      }
    }
  }
  `;

  // those values are read only
  // workspace Id only identify where to read data from
  const workspaceId = process.env.WORKSPACE_ID;
  // CPA_TOKEN is a ready only token to enable http requests to Contentful
  const cpaToken = process.env.CPA_TOKEN;

  useEffect(() => {
    window
      .fetch(
        `https://graphql.contentful.com/content/v1/spaces/${workspaceId}/`,
        {
          method: "POST",
          mode: "cors",
          credentials: "omit",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cpaToken}`,
          },
          body: JSON.stringify({ query }),
        }
      )
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setBlogPosts(data.pageCollection.items);
      });
  }, [workspaceId, cpaToken]);

  if (!blogPosts) {
    return (
      <section>
        <div className="blog-post">loading...</div>
      </section>
    );
  }

  const listAllPosts = blogPosts.map((item, idx) => {
    return (
      <li key={idx}>
        <a href={`blog/${item.slug}`}>{item.title}</a>
      </li>
    );
  });

  return (
    <section>
      <div className="blog-post">
        <h2>Blog</h2>
        <div className="blog-post-list">
          <ul>{listAllPosts}</ul>
        </div>
      </div>
    </section>
  );
};

export default BlogPosts;
