import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MainContent from "../../components/MainContent";
import BlogPost from "../../components/Blog/post";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import "./reset.css";
import "./style.css";


const App = () => {
		useEffect(() => {
				ReactGA.send({ hitType: "pageview", page: window.location.pathname });
		}, []);
		return (
				<div id="container">
						<div id="wrapper">
								<Header />
								<hr />
								<Routes>
										<Route index element={<MainContent />} />
										<Route path="blog/:slug" element={<BlogPost />} />
								</Routes>
								<hr />
								<Footer />
						</div>
				</div>
		)
};

export default App;
